<template>
  <pre class="yb-highlight"><code class="w-full" :class="['yb-' + language, 'language-' + language, wrap ? 'wrap' : 'nowrap']" v-html="code" /></pre>
</template>

<script>
import Prism, { fixups } from './prism'

export default {
  props: {
    content: [String, Function],
    language: String,
    lineNumbers: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      code: null
    }
  },
  watch: {
    content() {
      this.update()
    },
    language() {
      this.update()
    }
  },
  mounted() {
    fixups(Prism)
    this.update()
  },
  methods: {
    update() {
      Promise.resolve(this.content instanceof Function ? this.content() : this.content).then((content) => {
        const { language } = this
        if (content && language) {
          this.code = Prism.highlight(content, Prism.languages[language])
        } else {
          this.code = ''
        }
      })
    }
  }
}
</script>

<style scoped lang="postcss">

.yb-highlight,
.yb-highlight :deep(*) {
  @apply font-mono font-normal;
}

.yb-highlight,
.yb-highlight :deep(span) {
  font-size: 14px;
  word-wrap: break-word;
}

.yb-highlight.text-xs,
.yb-highlight.text-xs :deep(*) {
  font-size: 10px;
  line-height: 12px;
}

.yb-highlight.text-sm,
.yb-highlight.text-sm :deep(*) {
  font-size: 12px;
  line-height: 14px;
}

.yb-highlight .wrap,
.yb-highlight .wrap :deep(span) {
  @apply whitespace-pre-wrap;
}

.yb-highlight .nowrap,
.yb-highlight .nowrap :deep(span) {
  @apply whitespace-nowrap;
}

</style>
